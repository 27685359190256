import React from 'react'
import img_404 from '../img/404.svg'

const NotFoundPage = () => (
    <section className="section">
        <div className="container">
            <figure className="image">
                <img
                    alt="404 error"
                    src={img_404}
                    style={{ width: '100em', height: '40em' }}
                ></img>
            </figure>
            {/*<div className="content has-text-centered">*/}
            {/*    <h1>This page doesn&#39;t exist.</h1>*/}
            {/*    <p>*/}
            {/*        Please, click anywhere on the screen to come back to the*/}
            {/*        main page.*/}
            {/*    </p>*/}
            {/*</div>*/}
        </div>
    </section>
)

export default NotFoundPage
